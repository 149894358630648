import React, { useState, useEffect, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { AutoSuggest } from '../../features/Search/AutoSuggest'
import { GlobalSearchAUS } from '../../features/Search/GlobalSearch/GlobalSearchAUS'
import { ClinButton } from '../../components/ClinButton'
import {
  StyledPageHeader,
  StyledIcon,
  StyledSearchContainer,
  StyledRecentSearches,
  StyledButtonRow,
  StyledButtonRecentSearches,
  StyledClinButton,
  StyledRecentSearchesLabel,
  StyledContainer
} from './Homepage.styles'

import lightningIcon from './lightning-icon.svg'
import curvedArrowIcon from './curved-arrow-icon.svg'
import { ClinSpacer } from '../../components/ClinSpacer'
import { IBanner } from '../../types/IBanner'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  StyledOrdersAnnounce,
  StyledOrdersAnnounceCta,
  StyledOrdersAnnounceText
} from '../OrderPages/Orders/Orders.styles'
import { getBanner } from '../../services/ApiService'
import { useOnMount } from '../../utils/useOnMount'
import config from '../../services/ConfigProvider'

export const Homepage: React.FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [recentSearches, setRecentSearches] = useState<
    { searchTerm: string; url: string }[]
  >([])
  const [searchInput, setSearchInput] = useState<HTMLInputElement | null>(null)
  const [banner, setBanner] = useState<IBanner | null>(null)

  useEffect(() => {
    const storedSearches = JSON.parse(
      localStorage.getItem('recentSearches') || '[]'
    )
    setRecentSearches(storedSearches.length > 0 ? storedSearches : [])
  }, [])

  useOnMount(() => {
    if (config.showBanner === 'true' || config.showBanner === true) {
      getBanner().then((result) => {
        try {
          // Get data from squidEx dict using language as key accessor
          const bannerObj = result.data?.items[0].data.banner[
            i18n.language
          ] as IBanner
          setBanner(bannerObj)
        } catch (e) {
          console.warn('An error occurred fetching the banner')
        }
      })
    }
  })

  const handleRecentSearchClick = (searchTerm: string, url: string) => {
    if (searchInput) {
      searchInput.value = searchTerm
      searchInput.focus()
      // Dispatch an input event to trigger any listeners
      const event = new Event('input', { bubbles: true })
      searchInput.dispatchEvent(event)
    }
    history.push(url)
  }

  const handleViewOrders = () => {
    history.push('/orders')
  }

  const handleViewPatients = () => {
    history.push('/patients')
  }

  const inputRef = useCallback((node: HTMLInputElement) => {
    if (node !== null) {
      setSearchInput(node)
    }
  }, [])

  return (
    <StyledContainer>
      {banner && (
        <ClinAnnounceBar
          title={' '}
          mode={AnnounceMode.Information}
          marginBottom={0}
          marginTop={ClinTheme.space[5]}
        >
          <StyledOrdersAnnounce>
            <StyledOrdersAnnounceText>
              <strong>{banner.title}</strong>
              <br />
              {banner.description}
            </StyledOrdersAnnounceText>
            {banner.buttonTitle && banner.buttonUrl && (
              <StyledOrdersAnnounceCta>
                <a
                  href={banner.buttonUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ClinButton as="div" variant="primary">
                    {banner.buttonTitle}
                  </ClinButton>
                </a>
              </StyledOrdersAnnounceCta>
            )}
          </StyledOrdersAnnounce>
        </ClinAnnounceBar>
      )}
      <StyledPageHeader>
        <div style={{ position: 'relative' }}>
          <ClinText
            as="h1"
            color={ClinTheme.colors.black}
            fontWeight={ClinTheme.fontWeights.bolder}
            fontSize={ClinTheme.fontSizes[6]}
            lineHeight={ClinTheme.lineHeights.body}
            marginTop={ClinTheme.space[7]}
          >
            {t('product_catalogue:aus_title')}
            <StyledIcon
              width={30}
              height={38}
              src={lightningIcon}
              style={{ position: 'absolute', top: '55px', right: '-41px' }}
              className="lightening-icon"
            />
          </ClinText>
          <StyledIcon
            width={104}
            height={63}
            src={curvedArrowIcon}
            style={{ position: 'absolute', top: '80px', right: '-158px' }}
          />
        </div>
        <ClinSpacer height={15} />
        <ClinText
          className="description"
          variant={TypographyVariant.LargerParagraph}
          color={ClinTheme.colors.black}
        >
          <Trans
            i18nKey="product_catalogue:aus_description"
            components={{ bold: <strong /> }}
          />
        </ClinText>
      </StyledPageHeader>
      <ClinSpacer height={14} />
      <StyledSearchContainer>
        <AutoSuggest
          noResultsText={t('global_search:suggestion_text_aus')}
          maUserNoResultsText={t('global_search:ma_user_no_results_text')}
          suggestionText={t('global_search:suggestion_text_aus')}
          noResultsPath="/products/catalogue?q=*"
          suggestionTextColor={ClinTheme.colors.primaryMid}
          suggestionTextPadding={`${ClinTheme.space[3]}px`}
          borderRadiusLastLiElement="28"
          searchComponent={(props) => (
            <GlobalSearchAUS
              {...props}
              handleChange={(e) => {
                props.handleChange?.(e)
                inputRef(e.target as HTMLInputElement)
              }}
              handleEnter={(e) => {
                props.handleEnter?.(e)
              }}
            />
          )}
        />
      </StyledSearchContainer>

      {recentSearches.length > 0 && (
        <StyledRecentSearches>
          <StyledRecentSearchesLabel>
            Recent searches:
          </StyledRecentSearchesLabel>
          <StyledButtonRecentSearches>
            {recentSearches.map((item, index) => (
              <span
                key={index}
                className="button-text"
                onClick={() =>
                  handleRecentSearchClick(item.searchTerm, item.url)
                }
              >
                {item.searchTerm}
              </span>
            ))}
          </StyledButtonRecentSearches>
        </StyledRecentSearches>
      )}

      <ClinSpacer height={80} className="clin-spacer-recent-searches" />

      <StyledButtonRow>
        <StyledClinButton
          type="button"
          variant="secondary"
          onClick={handleViewOrders}
        >
          {t('common:buttons.view_all_orders')}
        </StyledClinButton>
        <ClinButton
          type="button"
          variant="primary"
          onClick={handleViewPatients}
          style={{ textTransform: 'none', width: '258px', height: '48px' }}
        >
          {t('common:buttons.view_all_patients')}
        </ClinButton>
      </StyledButtonRow>
    </StyledContainer>
  )
}
