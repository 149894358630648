import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinCard } from '../../components/ClinCard'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { MaDeliveryDetails } from '../../features/MaDeliveryDetails'
import { MaOrderPrice } from '../../features/MaOrderPrice'
import {
  HoldDto,
  OrderDto,
  OrderLineDto,
  OrderTrackingInfoDto,
  ProgramCatalogDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import {
  getOrderItems,
  getReassignedOrderItems,
  getTotalCharges
} from '../OpaCheckout/OpaCheckout.models'
import {
  StyledMaOrderDetailsButtonsGroup,
  StyleOpaAction
} from './MaOrderDetail.styles'
import { SectionTitleBar } from '../../features/SectionTitleBar'
import { MaOrderItems } from '../../features/MaOrderItems'
import { OrderItemType } from '../../features/OrderItems/OrderItem'
import { IHoldDetails } from '../../services/Holds/holds.types'
import { HoldAnnounce } from '../../features/HoldAnnounce'
import { InvoiceFlags } from '../../constants'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { IClinAnnounceBarProps } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useTranslation } from 'react-i18next'
import {
  getCarrierLogo,
  getCarrierType
} from '../OrderPages/OrderDetail/OrderDetail.model'
import { StyledOrderDetailShipping } from '../OrderPages/OrderDetail/OrderDetail.styles'
import i18n from '../../i18n/config'
import { OrderStatus } from '../../types/OrderStatus'
import { ClinNewFeatureTooltipContainer } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import {
  INewFeature,
  NewFeatureElements
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'

interface IMaOrderDetailProps {
  /** Whether order is loading or not  */
  isLoading: boolean
  /** Is equal false if at least one order line has a status equal "Shipped" or "Cancelled" */
  isOrderCancellable?: boolean | undefined
  /** Order details */
  order: OrderDto | undefined
  /** Program details */
  program: ProgramCatalogDto | undefined
  /** Provides order level holds to display above the order */
  orderLevelHolds?: IHoldDetails[]
  /** List of new features for this page **/
  newFeaturesList?: INewFeature[]
  /** patientAccessFormUrl */
  patientAccessFormUrl: string | undefined
  /** Back button title */
  backButtonTitle: string
  /** Whether treating physician is associated to the user or not */
  isPhysicianAssociated: boolean
  isEnrolledProgram: boolean
  /** Displays an announce at the top of a page **/
  orderAnnounce?: IClinAnnounceBarProps
  /** Displays any tracking info at top or order lines **/
  trackingInfo?: OrderTrackingInfoDto
  /** Handle click on "Completed Form" button */
  handlePatientAccessForm: () => void
  /** Handle click on "Cancel Order" button */
  handleCancelOrder: () => void
  /** Handle click on "Place Order Again" button */
  handlePlaceOrderAgain: () => void
  /** Request to go back to a page */
  handleGoBack: () => void
  /** Call back when file is uploaded for a hold */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void
  /** Handles the dismissal of the announce bar **/
  handleDismissAnnounce?: () => void
  /** Handles a request to email order details **/
  handleEmailOrderDetails?: () => void
}

export const MaOrderDetail: FunctionComponent<IMaOrderDetailProps> = ({
  isLoading,
  isOrderCancellable,
  order,
  program,
  orderLevelHolds,
  patientAccessFormUrl,
  backButtonTitle,
  isPhysicianAssociated,
  isEnrolledProgram,
  newFeaturesList,
  trackingInfo,
  handlePatientAccessForm,
  handleCancelOrder,
  handlePlaceOrderAgain,
  handleGoBack,
  handleFileUploadedForOrderAndHold,
  handleEmailOrderDetails,
  orderAnnounce,
  handleDismissAnnounce
}) => {
  const { t } = useTranslation()

  const patientNumber = order?.lines[0].patientNumber
  const hospitalRefNo = order?.lines[0].hospitalRefNo
  const orderedDateStr =
    order &&
    DateTime.fromISO(order?.orderedDate, { zone: 'utc' }).toLocaleString()
  const physician = order?.lines[0].physician
  const orderStatus = capitalize(order?.orderStatus ?? '')
  const charges = order ? getTotalCharges(order) : 0
  const poNumber = order?.customerPoNumber
    ? order.customerPoNumber
    : order?.orderNumber
    ? order.orderNumber
    : t('glossary:not_applicable')

  const orderedItems: OrderLineDto[] = getOrderItems(order)
  const reassignedItems: OrderLineDto[] = getReassignedOrderItems(order)
  const isDeliveredOrderWithAftership = (orderLine: OrderLineDto) => {
    const freightCarrier = getCarrierType(
      orderLine.trackingInfo?.freightCarrier
    )
    return (
      order?.orderStatus === OrderStatus.Delivered &&
      (freightCarrier === 'Aftership' || freightCarrier === 'Partial')
    )
  }

  const displayShippedHeader = order?.lines
    .filter((ol) => ol.medicationFlag === 'Y')
    .map(
      (orderLine) =>
        getCarrierType(orderLine.trackingInfo?.freightCarrier) !==
        'Not supported'
    )[0]

  return (
    <ClinPageContentFrame isLoading={isLoading}>
      {order && (
        <>
          <ClinText
            variant={TypographyVariant.H2}
            as="span"
            fontWeight={ClinTheme.fontWeights.bold}
            marginLeft={ClinTheme.space[2]}
          >
            {t('ma_order_detail:order_number', {
              orderNumber: order.orderNumber
            })}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[8]} hasBorder={true} />
          {backButtonTitle && (
            <ClinButton onClick={() => handleGoBack()} variant="linkWithIcon">
              <ClinIcon
                iconSize={ClinTheme.fontSizes[3]}
                iconName={ClinIconPathName.ArrowLeft}
              />
              {backButtonTitle}
            </ClinButton>
          )}
          {orderAnnounce && (
            <>
              <ClinSpacer height={ClinTheme.space[4]} />
              <ClinAnnounceBar
                handleClose={handleDismissAnnounce}
                mode={orderAnnounce.mode}
                title={orderAnnounce.title}
              >
                {orderAnnounce.message}
              </ClinAnnounceBar>
            </>
          )}
          {orderLevelHolds ? (
            <>
              <ClinSpacer height={ClinTheme.space[6]} />
              {orderLevelHolds.map((orderLevelHold, index) => (
                <HoldAnnounce
                  key={`order-error-${index}`}
                  mode={orderLevelHold.announceMode}
                  title={orderLevelHold.title}
                  marginBottom={ClinTheme.space[3]}
                >
                  {orderLevelHold.maMessage}
                </HoldAnnounce>
              ))}
              <ClinSpacer height={ClinTheme.space[7]} />
            </>
          ) : (
            <ClinSpacer height={ClinTheme.space[4]} />
          )}
          {order.lines
            .filter((ol) => ol.medicationFlag === 'Y')
            .reduce((uniqueLines: any, currentLine: any) => {
              const isDuplicate = uniqueLines.some(
                (line: any) =>
                  line.trackingInfo?.freightCarrier ===
                    currentLine.trackingInfo?.freightCarrier &&
                  line.trackingInfo?.waybillNumber ===
                    currentLine.trackingInfo?.waybillNumber &&
                  line.orderStatus === currentLine.orderStatus
              )
              if (!isDuplicate) {
                uniqueLines.push(currentLine)
              }
              return uniqueLines
            }, [])
            .map((orderLine: any) =>
              getCarrierType(orderLine.trackingInfo?.freightCarrier) !==
              'Not supported' ? (
                <ClinCard
                  key={`order-${orderLine.lineId}`}
                  removePadding={true}
                  marginBottom={0}
                  border={'1px solid #d2d2d2'}
                  borderBottomLeftRadius={0}
                  borderBottomRightRadius={0}
                  borderBottom={'none'}
                >
                  {/* Shipped header with extra details */}
                  {getCarrierType(orderLine.trackingInfo?.freightCarrier) !==
                    'Not supported' && (
                    <StyledOrderDetailShipping>
                      <Row>
                        <Col>
                          <ClinText
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('order_detail:status')}
                          </ClinText>
                          <ClinText
                            color={ClinTheme.colors.black}
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {order.orderStatus}
                          </ClinText>
                        </Col>
                        <Col>
                          <ClinText
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('order_detail:carrier')}
                          </ClinText>
                          <ClinText
                            color={ClinTheme.colors.black}
                            variant={TypographyVariant.LargeParagraph}
                            className={'clin-text-carrier'}
                          >
                            {orderLine.trackingInfo?.freightCarrier
                              ? getCarrierLogo(
                                  orderLine.trackingInfo?.freightCarrier
                                )
                              : t('glossary:not_available')}
                          </ClinText>
                        </Col>
                        <Col>
                          <ClinText
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('order_detail:tracking_link')}
                          </ClinText>
                          <ClinText
                            color={ClinTheme.colors.black}
                            variant={TypographyVariant.LargeParagraph}
                            className={'clin-text-waybill-na'}
                          >
                            {/* If no way bill or not Aftership then show not available */}
                            {orderLine.trackingInfo?.waybillNumber &&
                            getCarrierType(
                              orderLine.trackingInfo?.freightCarrier
                            ) === 'Aftership' ? (
                              !!orderLine.trackingInfo
                                ?.aftershipTrackingLink ? (
                                <span
                                  className="tooltip-span"
                                  style={{ display: 'flex' }}
                                >
                                  <ClinButton
                                    variant="linkButton"
                                    as="a"
                                    target="_blank"
                                    href={
                                      orderLine.trackingInfo
                                        ?.aftershipTrackingLink
                                    }
                                    className={'clin-text-waybill'}
                                    style={{ height: 'unset' }}
                                  >
                                    {orderLine.trackingInfo?.waybillNumber}
                                  </ClinButton>
                                  <span
                                    className="tooltip-span"
                                    style={{ marginLeft: '10px' }}
                                  >
                                    {/* Display new feature for tracking link ********************************************* */}
                                    {newFeaturesList?.find(
                                      (x) =>
                                        x.id ===
                                        NewFeatureElements.TrackingLinkFeature
                                    ) && (
                                      <ClinNewFeatureTooltipContainer
                                        feature={
                                          newFeaturesList.find(
                                            (x) =>
                                              x.id ===
                                              NewFeatureElements.TrackingLinkFeature
                                          ) as INewFeature
                                        }
                                      ></ClinNewFeatureTooltipContainer>
                                    )}
                                  </span>
                                </span>
                              ) : (
                                t('order_detail:waiting_for_carrier')
                              )
                            ) : (
                              t('glossary:not_available')
                            )}
                          </ClinText>
                        </Col>
                        {/* For orders with aftership or partial and order status Delivered do not show estimated deliery date section. */}
                        {isDeliveredOrderWithAftership(orderLine) ? (
                          <Col></Col>
                        ) : (
                          <Col>
                            <ClinText
                              color={ClinTheme.colors.primary}
                              fontWeight={ClinTheme.fontWeights.medium}
                            >
                              {t('product_detail:estimated_delivery_title')}
                            </ClinText>
                            <ClinText
                              color={ClinTheme.colors.black}
                              variant={TypographyVariant.LargeParagraph}
                              className={'clin-text-carrier'}
                            >
                              {orderLine.trackingInfo?.estimatedDeliveryDate
                                ? DateTime.fromISO(
                                    orderLine.trackingInfo
                                      ?.estimatedDeliveryDate || '',
                                    { zone: 'utc' }
                                  )
                                    .setLocale(i18n.language)
                                    .toFormat('cccc, MMMM d')
                                : orderLine.expectedDate
                                ? DateTime.fromISO(orderLine.expectedDate || '')
                                    .setLocale(i18n.language)
                                    .toFormat('cccc, MMMM d')
                                : t('glossary:not_available')}
                            </ClinText>
                          </Col>
                        )}
                      </Row>
                    </StyledOrderDetailShipping>
                  )}
                </ClinCard>
              ) : null
            )}
          <Row>
            <Col>
              <ClinCard
                borderTop={
                  !displayShippedHeader
                    ? `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`
                    : 'none'
                }
                borderTopLeftRadius={0}
                borderTopRightRadius={0}
              >
                <ClinText
                  as="h3"
                  variant={TypographyVariant.H3}
                  marginTop={'0'}
                >
                  {t('ma_order_detail:title')}
                </ClinText>
                <Row>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('ma_order_detail:order_details_card.number')}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {order?.orderNumber}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('ma_order_detail:order_details_card.date_submitted')}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {orderedDateStr}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('ma_order_detail:order_details_card.patient_id')}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {isPhysicianAssociated && isEnrolledProgram
                        ? patientNumber ||
                          t('glossary:not_applicable_long_hand')
                        : t('glossary:confidential')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('ma_order_detail:order_details_card.institute_id')}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {isPhysicianAssociated && isEnrolledProgram
                        ? hospitalRefNo || t('glossary:not_available')
                        : t('glossary:confidential')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('ma_order_detail:order_details_card.status')}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {orderStatus}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>

                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t(
                        'ma_order_detail:order_details_card.treating_physician'
                      )}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {isPhysicianAssociated && isEnrolledProgram
                        ? `${physician?.physicianTitle ?? ''} ${
                            physician?.physicianFirstName
                          } ${physician?.physicianLastName}`
                        : t('glossary:confidential')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t(
                        'ma_order_detail:order_details_card.patient_access_form'
                      )}
                    </ClinText>
                    {isPhysicianAssociated && isEnrolledProgram ? (
                      <StyleOpaAction
                        onClick={
                          isPhysicianAssociated
                            ? handlePatientAccessForm
                            : undefined
                        }
                      >
                        <ClinIcon
                          iconName={ClinIconPathName.Document}
                          iconFill={ClinTheme.colors.primary}
                          style={{ marginLeft: -ClinTheme.space[1] }}
                        />
                        <ClinText
                          marginLeft={ClinTheme.space[2]}
                          fontSize={ClinTheme.fontSizes[2]}
                          fontWeight={ClinTheme.fontWeights.bold}
                          color={ClinTheme.colors.primary}
                        >
                          {patientAccessFormUrl
                            ? t(
                                'ma_order_detail:order_details_card.access_form_completed'
                              )
                            : t(
                                'ma_order_detail:order_details_card.access_form_error'
                              )}
                        </ClinText>
                      </StyleOpaAction>
                    ) : (
                      <ClinText
                        as="p"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {t('glossary:confidential')}
                      </ClinText>
                    )}
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      color={ClinTheme.colors.primary}
                      marginBottom={ClinTheme.space[2]}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('ma_order_detail:order_details_card.submitted_by')}
                    </ClinText>
                    <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                      {order?.shipToContact ?? t('glossary:not_applicable')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                  </Col>
                </Row>

                {/*CLOS-9826 - INC0032071 Hide the 'Email Invoice details' button*/}
                {order.invoiceFlag === InvoiceFlags.Y.toString() && (
                  <Row>
                    <Col style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEmailOrderDetails && handleEmailOrderDetails()
                        }
                        justifyContent="center"
                        variant="linkButton"
                      >
                        {t('ma_order_detail:email_invoice_button')}
                        <ClinIcon
                          style={{ marginLeft: ClinTheme.space[2] }}
                          iconSize={ClinTheme.fontSizes[3]}
                          iconName={ClinIconPathName.Mail}
                        />
                      </ClinButton>
                    </Col>
                  </Row>
                )}
              </ClinCard>
              <ClinSpacer height={ClinTheme.space[3]} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <MaDeliveryDetails
                deliverTo={order.deliverTo}
                deliverToContact={order.deliverToContact}
                poNumber={poNumber}
                deliveryMethod={'Custom'}
              />
            </Col>
            <Col sm={12} md={6}>
              <MaOrderPrice
                subtotal={order.totals.subTotal}
                charges={charges}
                nominalCharge={order.totals.charges}
                tax={order.totals.tax}
                total={order.totals.total}
                currencyCode={order.currencyCode}
              />
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          {reassignedItems.length > 0 && (
            <>
              <SectionTitleBar title={t('ma_order_detail:title_reassigned')} />
              <ClinSpacer height={ClinTheme.space[5]} />
              <MaOrderItems
                orderId={order.orderNumber}
                orderStatus={order.orderStatus}
                orderLines={reassignedItems}
                isShowingProductDetails={false}
                programSponsor={program?.sponsor}
                currencyCode={order?.currencyCode}
                itemType={OrderItemType.ReassignedItem}
                handleFileUploadedForOrderAndHold={
                  handleFileUploadedForOrderAndHold
                }
              />
              <ClinSpacer height={ClinTheme.space[5]} />
            </>
          )}
          <SectionTitleBar title={t('ma_order_detail:title_ordered')} />
          {orderedItems?.length > 0 && (
            <>
              <ClinSpacer height={ClinTheme.space[6]} />
              <StyledMaOrderDetailsButtonsGroup>
                {isOrderCancellable && (
                  <ClinButton variant="secondary" onClick={handleCancelOrder}>
                    {t('ma_order_detail:cancel_order_button')}
                  </ClinButton>
                )}
                <ClinButton variant="secondary" onClick={handlePlaceOrderAgain}>
                  {t('ma_order_detail:order_again_button')}
                </ClinButton>
              </StyledMaOrderDetailsButtonsGroup>
            </>
          )}
          <ClinSpacer height={ClinTheme.space[6]} />
          <MaOrderItems
            orderId={order.orderNumber}
            orderLines={orderedItems}
            programSponsor={program?.sponsor}
            currencyCode={order?.currencyCode}
            itemType={OrderItemType.OrdinaryItem}
            isFileUploadEnabled={true}
            handleFileUploadedForOrderAndHold={
              handleFileUploadedForOrderAndHold
            }
          />
          <ClinSpacer height={ClinTheme.space[6]} />
        </>
      )}
    </ClinPageContentFrame>
  )
}
