import React, { FunctionComponent } from 'react'
import { ClinDropdown } from '../../components/ClinDropdown'
import { ClinDropdownMenu } from '../../components/ClinDropdownMenu'
import authService from '../../services/AuthService'
import { ClinTheme } from '../../ClinTheme'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../components/ClinIcon'
import { createShowInstituteModalEvent } from '../../events/InstituteModalEvent'
import { useAppContext } from '../../context/app'
import { useBasket } from '../../context/basket'
import { getUserRoleWithCountry, UserRole, UserRoleRecord, UserRoleWithCountry } from '../../constants'
import { StyledNavIconsMobileContainer } from './NavIconsBarContainer.styles'
import { noop } from '../../utils/noop'
import { useTranslation } from 'react-i18next'
import { ClinNavUpperItem } from '../../components/ClinNavItem/ClinNavItem'
import { StyledNavTextAndChevron } from '../../components/ClinNavIconButton/ClinNavIconButton.styles'
import {
  StyledBasketCounter,
  StyledMainItemsName
} from '../../components/ClinNavbar/ClinNavbar.styles'
import { ClinNavIconButton } from '../../components/ClinNavIconButton'
import { DropdownUpperNavButton } from '../../features/DropdownNavButton/DropdownUpperNavButton'

export const NavIconsBarContainer: FunctionComponent = () => {
  const { t } = useTranslation()
  const { dispatch, userRole, institute, portfolioCountryCode } = useAppContext()
  const [{ basket }] = useBasket()

  const isMaUser = userRole && UserRoleRecord[userRole as UserRole].isMaUser

  const userRoleWithCountry = getUserRoleWithCountry(
    portfolioCountryCode,
    userRole
  )
  
  const excludedRoleWithCountry = [
    UserRoleWithCountry.AusBase,
    UserRoleWithCountry.AusBaseMa,
    UserRoleWithCountry.Base,
    UserRoleWithCountry.BaseMa
  ]

  const getBadgeValue = (value: number): string => {
    return value > 99 ? '99+' : value.toString()
  }

  return (
    <StyledNavIconsMobileContainer className="nav-icons-container">
      {
        <>
          <ClinNavIconButton
            title={institute?.data?.instituteName ?? ''}
            iconName={ClinIconPathName.ChangeInstituteMobile}
            toLink="/institute/details"
            disabled={true}
            iconViewBox="0 0 24 24"
            className="institution-name block-institute-picker"
            onClick={() => dispatch(createShowInstituteModalEvent())}
          ></ClinNavIconButton>
          <ClinDropdown
            showHover={true}
            alignment="right"
            isMyAccount={true}
            trigger={() => (
              <ClinNavIconButton
                className="my-account"
                title={t('navigation:my_account')}
                iconName={ClinIconPathName.Edit}
                toLink="/account/details"
                disabled={true}
              ></ClinNavIconButton>
            )}
          >
            <ClinDropdownMenu
              className="account-menu"
              style={{
                padding: 0,
                boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
                borderRadius: '3px'
              }}
            >
              <div style={{ paddingBottom: '8px' }}>
                {userRole && (
                  <>
                    <DropdownUpperNavButton
                      className="my-account"
                      to={`/account/details`}
                    >
                      {t('navigation:my_account')}
                    </DropdownUpperNavButton>
                    <DropdownUpperNavButton to={`/institute/details`}>
                      {t('navigation:my_institute')}
                    </DropdownUpperNavButton>
                    {(userRoleWithCountry !== undefined && 
                      !excludedRoleWithCountry.includes(userRoleWithCountry)) &&
                          <DropdownUpperNavButton to={'/new-user'}>
                            {t('navigation:invite_colleague')}
                          </DropdownUpperNavButton>}
                    <DropdownUpperNavButton to={`/preferences`}>
                      {t('navigation:preferences')}
                    </DropdownUpperNavButton>
                  </>
                )}
                <DropdownUpperNavButton
                  className={'block-log-out'}
                  to={`/logout`}
                  onClick={(event) => {
                    event.preventDefault()
                    authService.logout().then(() => noop())
                  }}
                >
                  {t('navigation:log_out')}
                </DropdownUpperNavButton>
              </div>
            </ClinDropdownMenu>
          </ClinDropdown>
          {!isMaUser && (
            <ClinNavIconButton
              title={t('navigation:bookmarks')}
              iconName={ClinIconPathName.Bookmark}
              toLink="/bookmarks"
              id="bookmarks"
            ></ClinNavIconButton>
          )}
          {userRole && !isMaUser && (
            <StyledNavTextAndChevron
              style={{ marginRight: '15px', marginTop: '0' }}
              className="basket-link"
            >
              <ClinNavUpperItem
                to={'/basket'}
                exact={true}
                strict={true}
                style={{ position: 'relative' }}
              >
                <ClinIcon
                  style={{ marginRight: '5px' }}
                  iconName={ClinIconPathName.NewBasket}
                  iconSize={'27px'}
                  iconHeight={'19px'}
                  iconFill={ClinTheme.colors.white}
                  viewBox="0 0 27 22"
                />
                {basket.data && basket.data.items.length > 0 && (
                  <StyledBasketCounter className="basket basket-items">
                    {getBadgeValue(basket?.data?.items?.length)}
                  </StyledBasketCounter>
                )}
                <StyledMainItemsName>
                  {t('navigation:basket')}
                </StyledMainItemsName>
              </ClinNavUpperItem>
            </StyledNavTextAndChevron>
          )}
        </>
      }
    </StyledNavIconsMobileContainer>
  )
}
