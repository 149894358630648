/**
 * Clinigen Styled Components theme
 */
export const ClinTheme = {
  colors: {
    primary: '#270072',
    primaryMid: `#501ECE`,
    primaryLight: '#7239ff',
    primaryLighter: '#7244FB',
    primaryLightest: '#8c88ff',
    primaryDark: '#20015B',
    secondary: '#8cdb1f',
    magenta: '#d25eff',
    deepGrey: '#979797',
    grey: '#d2d2d2',
    midGrey: '#e7e8e8',
    lightGrey: '#F7F4F1',
    ultraLightGrey: '#f8f8f8',
    darkGrey: '#757575',
    black: '#000000',
    white: '#ffffff',
    transparent: 'rgba(0,0,0,0)',
    greenValid: '#00A223',
    aquaGreen: '#a9ebca', // Notification button color
    orangeWarn: '#d76a00',
    redInvalid: '#DB0808',
    lightBlue: '#dcf1ff',
    darkGreen: '#00524E', // CliniPort
    orange: '#d76a00',
    lightGreen: '#62bd19', //Tooltip dot color
    green: '#00A528', //Tooltip dot color
    lavender: '#E5DDF8',
    darkPurple: '#3d0081', //basket color
    darkPurple2: '#35007c', //basket color
    darkPurple3: '#36007B', //basket color
    darkPurple4: '#2E0077', //basket color
    lightPurple: '#EEEDFF', // one step checkout color
    tealishBlue: '#0043CE', //Status color on PCJs
    lightenGreen: '#E5F6E9',
    redOrange: '#FF635B',

    // New status-specific colors
    statusGreen: '#E6F4EA',
    statusGreenText: '#1E8E3E',
    statusRed: '#FCE8E6',
    statusRedText: '#D93025',
    statusOrange: '#FEF7E0',
    statusOrangeText: '#F29900',
    statusGrey: '#F1F3F4',
    statusGreyText: '#5F6368',

    // New tooltip colors
    tooltipBackground: '#616161',
    tooltipText: '#FFFFFF'
  },

  statusTooltipColors: {
    submitted: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    undermedicalreview: {
      bg: '#FEF7E0',
      text: '#F29900'
    },
    healthauthorityreview: {
      bg: '#FEF7E0',
      text: '#F29900'
    },
    medicalreviewdeclined: {
      bg: '#FCE8E6',
      text: '#D93025'
    },
    healthauthoritydeclined: {
      bg: '#FCE8E6',
      text: '#D93025'
    },
    processing: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    outfordelivery: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    intransit: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    delivered: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    faileddelivery: {
      bg: '#FCE8E6',
      text: '#D93025'
    },
    pending: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    shipped: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    canceled: {
      bg: '#F1F3F4',
      text: '#5F6368'
    },
    failedattempt: {
      bg: '#FCE8E6',
      text: '#D93025'
    },
    availableforpickup: {
      bg: '#E6F4EA',
      text: '#1E8E3E'
    },
    default: {
      bg: '#F1F3F4',
      text: '#5F6368'
    }
  },

  fonts: {
    heading: `'HCo Gotham', sans-serif`,
    body: `'HCo Gotham',  sans-serif`
  },
  //          sm  h5  p   p   h4  h3  h2  h1
  fontSizes: [12, 14, 16, 18, 24, 32, 40, 64],

  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
    bolder: 800
  },

  lineHeights: {
    //    p
    body: 1.5,
    //    small
    small: 1.14,
    //    p (larger)
    largeParagraph: 1.33,
    //        h5    h4    h3    h2    h1
    heading: [1.71, 1.33, 1.25, 1.2, 1.13]
  },

  letterSpacing: [0.2, 0.4, 0.7],

  // Ratio 0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8
  space: [
    0,
    4,
    8, // spacing-xxs
    16, // spacing-xs
    24, // spacing-s
    32, // spacing-m
    40, // spacing-l
    48, // spacing-xl
    56, // spacing-xxl
    64, // spacing-xxxl
    90 // spacing-xxxxl
  ],

  radii: [0, 2, 4, 8, 16, 24, 32, 64, 128],

  borderWidths: [1, 2, 3, 4, 5, 6],

  shadows: [
    `0 8px 8px rgba(0,0,0,0.08), 0 8px 8px rgba(0,0,0,0.08)`,
    `0px 4px 8px 0px rgba(0, 0, 0, 0.25)}`,
    `0px 0px 8px 0px rga(0, 0, 0, 0.16)`
  ],

  zIndices: {
    stickyTableRow: 2,
    stickyTableRowTop: 3,
    newFeatureFloatingUi: 3,
    suggestiveSearch: 4,
    confirmation: 5,
    dropdown: 6,
    drawer: 998,
    modal: 999
  },

  breakpoints: [576, 768, 1200, 1200, 1600, 551],

  containerWidths: [540, 740, 960, 1140, 1440],

  componentsHeights: {
    // header height
    header: 90,
    //  search component height
    search: 85,
    // footer height
    footer: 139,
    // patient header
    patientHeader: 146
  }
}
