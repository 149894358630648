import React, { FunctionComponent } from 'react'
import {
  StyledClinAnnounceBar,
  StyledMessage,
  StyledCloseIcon
} from './ClinAnnounceBar.styles'
import { ClinText } from '../ClinText'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { useTranslation } from 'react-i18next'

export enum AnnounceMode {
  Success = 'Success',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error'
}

interface IAnnounce {
  /** The translation key used for retrieving content from squidex */
  transKey: string
}

/**
 * Look up record/table for returning an announce title value
 * @usage:
 *
 * const { transKey } = announceModeRecord[AnnounceMode.Success]
 *
 */
export const announceModeRecord: Record<AnnounceMode, IAnnounce> = {
  [AnnounceMode.Success]: {
    transKey: 'announce_mode:success'
  },
  [AnnounceMode.Information]: {
    transKey: 'announce_mode:information'
  },
  [AnnounceMode.Warning]: {
    transKey: 'announce_mode:warning'
  },
  [AnnounceMode.Error]: {
    transKey: 'announce_mode:error'
  }
}

export interface IClinAnnounceBarProps {
  /** The title of the announce - defaults to the mode */
  title?: string
  /** The message of the announce */
  message?: string
  /** The mode of the announce */
  mode?: AnnounceMode
  /** Determines if the close x is displayed */
  isDismissible?: boolean
  /** Add bottom margin */
  marginBottom?: number
  /** Add top margin */
  marginTop?: number
  /** The mode of the announce */
  handleClose?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const getIconNameForMode = (mode: AnnounceMode): ClinIconPathName => {
  switch (mode) {
    case AnnounceMode.Information:
      return ClinIconPathName.InfoOutline
    case AnnounceMode.Success:
      return ClinIconPathName.CheckOutline
    case AnnounceMode.Error:
      return ClinIconPathName.ExclamationOutline
    default:
      return ClinIconPathName.ExclamationOutline
  }
}

export const ClinAnnounceBar: FunctionComponent<IClinAnnounceBarProps> = ({
  children,
  title,
  message,
  mode = AnnounceMode.Error,
  isDismissible,
  marginBottom,
  marginTop,
  handleClose
}) => {
  const { t } = useTranslation()
  return (
    <StyledClinAnnounceBar
      mode={mode}
      marginBottom={marginBottom}
      marginTop={marginTop}
      className="clin-announce"
    >
      <ClinIcon iconName={getIconNameForMode(mode)} />
      <StyledMessage>
        <ClinText
          as="div"
          fontWeight={ClinTheme.fontWeights.bold}
          color={'inherit'}
        >
          {title ?? t(`${announceModeRecord[mode].transKey}`)}
        </ClinText>
        <ClinText as="div" color={'inherit'}>
          {children || message}
        </ClinText>
      </StyledMessage>
      {isDismissible && (
        <StyledCloseIcon onClick={(event) => handleClose?.(event)}>
          <ClinIcon iconName={ClinIconPathName.Cross} />
        </StyledCloseIcon>
      )}
    </StyledClinAnnounceBar>
  )
}
