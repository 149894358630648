import {
  CatalogItemDto,
  CmPortfolioDto,
  PortfolioRuleDto,
  PriceAndAvailabilityDto,
  StockDto,
  WarehouseDto
} from '../../types/swaggerTypes'
import {
  DrugDivisionType,
  IAugmentedCatalogItemDto,
  UIVariationState
} from './PortfolioJourney.types'
import { $enum } from 'ts-enum-util'
import { DateTime } from 'luxon'
import { ClinTheme } from '../../ClinTheme'
import {
  ProductItemAvailabilityStatus,
  ProductItemAvailabilityStatusKey,
  SourceSystem
} from '../../constants'
import _ from 'lodash'
import { i18nRef } from '../../i18n/i18nRef'
import { getDefaultWarehouseCode } from '../../pages/Products/ProductVariant/ProductVariant.models'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'

export interface IPortfolioState {
  uiVariationState: UIVariationState
  endpoint: number
}

/**
 * Look up the portfolio journey type for a given ship to country
 * @param portfolioCountryCodeCode
 * @param rules
 */
export const getVariantForSkuGivenCountry = (
  rules: PortfolioRuleDto[],
  portfolioCountryCodeCode: string
): IPortfolioState => {
  // Default to UK for this release
  if (!portfolioCountryCodeCode) {
    portfolioCountryCodeCode = 'GB'
  }
  // Check if country exists
  let portfolioJourney: PortfolioRuleDto | undefined = rules.find(
    (portfolio) =>
      portfolio.country.split(',').indexOf(portfolioCountryCodeCode) > -1
  )

  // If no country check for default
  if (!portfolioJourney) {
    portfolioJourney = rules.find(
      (portfolio) => portfolio.country === '_Default'
    )
  }

  const uiVariationState = portfolioJourney
    ? $enum(UIVariationState).asValueOrDefault(
        portfolioJourney.journey,
        UIVariationState.Undefined
      )
    : UIVariationState.Undefined

  return {
    uiVariationState,
    endpoint: portfolioJourney?.endpoint ?? -1
  }
}

// A.C
// Given a user with default warehouses
// When viewing a product variant page that shows stock amounts
// Then the amount should show aggregated stock amounts for only the default warehouses
//
// Given a user without a default warehouse
// When viewing a product variant page that shows stock amounts
// Then show 'Available'
//
// Given a user with a default warehouse(s)
// When viewing the product variant page
// AND default warehouse have 0 stock
// Then display 'Available'

/**
 * Gets all information for a particular catalogItem
 */
export const getVariantInformationForCatalogItem = (
  catalogItem: CatalogItemDto,
  portfolioCountryCode: string,
  warehouse?: WarehouseDto,
  defaultWarehouses?: WarehouseDto[],
  availableQuantity?: string,
  pricingAndAvailability?: PriceAndAvailabilityDto
): IAugmentedCatalogItemDto => {
  // Augment the SKU with availability and dispatch information
  const { uiVariationState, endpoint } = getVariantForSkuGivenCountry(
    catalogItem.portfolioRules || [],
    portfolioCountryCode
  )

  // TSE or Restricted or Supply Issues?
  const isRestricted = isAusProductWithSupplyProblem(
    portfolioCountryCode,
    catalogItem
  )
    ? getRestrictedAusProduct(catalogItem)
    : getRestrictedProduct(catalogItem)

  // Check availability for warehouse
  const availability = getStockLevelsForWarehouse(
    portfolioCountryCode,
    warehouse?.warehouseCode ??
      catalogItem.item.defaultWarehouse ??
      getDefaultWarehouseCode(portfolioCountryCode),
    catalogItem.stockLevels,
    uiVariationState,
    defaultWarehouses,
    isRestricted,
    availableQuantity,
    catalogItem
  )

  // Get dispatch time message from warehouse information (taking into account cutoff time)
  const dispatchText = getDispatchTimeMessage(
    warehouse,
    catalogItem.stockLevels,
    uiVariationState,
    defaultWarehouses,
    isRestricted
  )

  // We can only reorder good stock or accurate stock items
  const canReOrder = getCanReOrder(
    uiVariationState,
    availability.outOfStock,
    isRestricted
  )

  // 6, 7, 14, 15 go to sourcing request form
  const canAccessSourcingRequestForm =
    uiVariationState === UIVariationState.AvailableOnRequestOnRequest || // 7, 14, 15
    uiVariationState === UIVariationState.AvailableOnRequestSourcingRequest // 6

  // 10, 11, 12 trigger a service cloud ticket
  const canRegisterInterest =
    uiVariationState === UIVariationState.AvailableOnRequestRegisterInterest || // 10, 11
    uiVariationState === UIVariationState.SupplyDisruptionRegisterInterest // 12

  const showDistributorInfo =
    uiVariationState === UIVariationState.AvailableDistributor

  // Get distributor name from cmPortfolio
  let distributorName: string | undefined
  if (showDistributorInfo) {
    const cmPortfolio: CmPortfolioDto | undefined | null =
      catalogItem.cmPortfolio.find((p) => p.country === portfolioCountryCode)
    distributorName = cmPortfolio?.distributor ?? undefined
  }

  return {
    ...catalogItem,
    availableStatus: availability.availableStatus,
    availableText: availability.displayText,
    availableColour: availability.colour,
    reservableStock: availability.reservableStock,
    dispatchText: dispatchText,
    cannotOrder: !canReOrder,
    canRegisterInterest,
    canAccessSourcingRequestForm,
    showDistributorInfo,
    distributorName,
    uiVariantState: uiVariationState,
    endpoint,
    expectedDate: pricingAndAvailability?.expectedDate,
    cutOffTime: pricingAndAvailability?.cutOffTime
  }
}

/**
 * Work out whether a product can be re-ordered
 * @param uiVariationState
 * @param isOutOfStock
 * @param isRestricted
 */
export const getCanReOrder = (
  uiVariationState: UIVariationState,
  isOutOfStock: boolean = false,
  isRestricted: boolean = false
): boolean => {
  // All permutations of Available Good Stock journey should be able to be ordered
  if (uiVariationState === UIVariationState.AvailableGoodStock) {
    return true
  }

  // Journey 16 should be able to unless its BOTH out of stock and is restricted
  if (uiVariationState === UIVariationState.AvailableAccurateStockLevel) {
    if (isOutOfStock && isRestricted) {
      return false
    }
    // If its just out of stock or if its just restricted its OK
    return true
  }

  // For every other journey it cannot be reordered
  return false
}

// //if user is from aus, for sage products with supply problem display different display text - clos-2295
const isAusProductWithSupplyProblem = (
  countryCode: string,
  catalogItem?: CatalogItemDto | null
) => {
  return (
    catalogItem &&
    countryCode === CountryAlphaCodes.Australia &&
    catalogItem?.item.sourceSystem === SourceSystem.Sage &&
    catalogItem.item.supplyProblem === 'Y'
  )
}

/**
 * Sum stock levels from an array of stocks and codes
 * @param stockLevels
 * @param defaultWarehouses
 */
export const getSumOfStockLevels = (
  stockLevels: StockDto[],
  defaultWarehouses: WarehouseDto[]
) => {
  // Get warehouse codes out
  const defaultWarehouseCodes = defaultWarehouses.map((w) => w.warehouseCode)
  // Get stock levels we need to deal with
  const targetStockLevels = stockLevels.filter((stock) =>
    defaultWarehouseCodes.includes(stock.warehouse)
  )
  return targetStockLevels.reduce(
    (prevSl, currSl) => prevSl + currSl.reservableStock,
    0
  )
}

export interface IStockLevel {
  displayText: string
  availableStatus: ProductItemAvailabilityStatus
  colour: string
  outOfStock: boolean
  reservableStock?: number
}

/**
 * Calculate the stock level for a given warehouse & return message & colour
 * @param warehouseCode
 * @param stockLevels
 * @param variant
 * @param defaultWarehouses
 * @param isRestricted
 * @param availableQuantity (from P&A call)
 */
export const getStockLevelsForWarehouse = (
  countryCode: string,
  warehouseCode: string | null | undefined,
  stockLevels: StockDto[],
  variant: UIVariationState,
  defaultWarehouses?: WarehouseDto[],
  isRestricted?: boolean,
  availableQuantity?: string,
  catalogItem?: CatalogItemDto | undefined | null
): IStockLevel => {
  const selectedWarehouse: StockDto | undefined = stockLevels.find(
    (w) => w.warehouse === warehouseCode
  )

  let stockLevel = 0
  // Are we on Product detail page and have available stock quantity from P&A call?
  if (!availableQuantity) {
    // If not get stock levels from ETL
    stockLevel = selectedWarehouse
      ? Math.max(0, selectedWarehouse.reservableStock)
      : 0
    // (Product variant page) Default warehouse - if its journey 16 (AvailableAccurateStockLevel) then sum up stock levels from all available warehouses
    if (
      variant === UIVariationState.AvailableAccurateStockLevel &&
      defaultWarehouses &&
      defaultWarehouses.length > 1
    ) {
      stockLevel = getSumOfStockLevels(stockLevels, defaultWarehouses)
    }
  } else {
    // We have P&A information - use it!
    stockLevel = parseInt(availableQuantity, 0)
  }

  const outOfStock = stockLevel <= 0
  switch (variant) {
    case UIVariationState.AvailableAccurateStockLevel: {
      const isAusProductWithSupplyProblemFlag = isAusProductWithSupplyProblem(
        countryCode,
        catalogItem
      )

      let availableStatus = getAvailableStatus(
        !!isAusProductWithSupplyProblemFlag,
        !!isRestricted,
        outOfStock
      )

      let displayText = getDisplayText(
        !!isAusProductWithSupplyProblemFlag,
        !!isRestricted,
        outOfStock,
        stockLevel
      )

      let color = getVariantColor(
        !!isAusProductWithSupplyProblemFlag,
        !!isRestricted,
        outOfStock
      )

      return {
        availableStatus,
        displayText: displayText,
        colour: color,
        outOfStock: outOfStock,
        reservableStock: stockLevel
      }
    }
    case UIVariationState.AvailableGoodStock:
      return {
        availableStatus: ProductItemAvailabilityStatus.AVAILABLE,
        displayText: i18nRef.transFn(
          ProductItemAvailabilityStatusKey.AVAILABLE
        ),
        colour: ClinTheme.colors.greenValid,
        outOfStock: outOfStock,
        reservableStock: stockLevel
      }
    case UIVariationState.GloballyUnavailable:
      return {
        availableStatus: ProductItemAvailabilityStatus.UNAVAILABLE_ONLINE,
        displayText: i18nRef.transFn(
          ProductItemAvailabilityStatusKey.UNAVAILABLE_ONLINE
        ),
        colour: ClinTheme.colors.redInvalid,
        outOfStock: outOfStock
      }
    case UIVariationState.Hidden:
      return {
        availableStatus: ProductItemAvailabilityStatus.HIDDEN,
        displayText: '',
        colour: ClinTheme.colors.redInvalid,
        outOfStock: outOfStock
      }
    case UIVariationState.AvailableOnRequestOnRequest:
      return {
        availableStatus: ProductItemAvailabilityStatus.AVAILABLE_ON_REQUEST,
        displayText: i18nRef.transFn(
          ProductItemAvailabilityStatusKey.AVAILABLE_ON_REQUEST
        ),
        colour: ClinTheme.colors.greenValid,
        outOfStock: outOfStock
      }
    case UIVariationState.AvailableDistributor:
      return {
        availableStatus: ProductItemAvailabilityStatus.AVAILABLE,
        displayText: i18nRef.transFn(
          ProductItemAvailabilityStatusKey.AVAILABLE
        ),
        colour: ClinTheme.colors.greenValid,
        outOfStock: outOfStock
      }
    case UIVariationState.AvailableOnRequestRegisterInterest:
    case UIVariationState.SupplyDisruptionRegisterInterest:
    case UIVariationState.AvailableOnRequestSourcingRequest:
      return {
        availableStatus: ProductItemAvailabilityStatus.UNAVAILABLE,
        displayText: i18nRef.transFn(
          ProductItemAvailabilityStatusKey.UNAVAILABLE
        ),
        colour: ClinTheme.colors.orangeWarn,
        outOfStock: true
      }
    default:
      return {
        availableStatus: ProductItemAvailabilityStatus.AVAILABLE,
        displayText: i18nRef.transFn(
          ProductItemAvailabilityStatusKey.AVAILABLE
        ),
        colour: ClinTheme.colors.greenValid,
        outOfStock: outOfStock
      }
  }
}

/**
 * Get the message to display for the dispatch time
 * @param warehouse
 * @param stockLevels
 * @param variant
 * @param defaultWarehouses
 * @param isRestricted
 */
export const getDispatchTimeMessage = (
  warehouse: WarehouseDto | undefined,
  stockLevels: StockDto[],
  variant?: UIVariationState,
  defaultWarehouses?: WarehouseDto[],
  isRestricted?: boolean
): string => {
  // No shipping information for following journey's
  if (
    variant === UIVariationState.GloballyUnavailable ||
    variant === UIVariationState.SupplyDisruptionRegisterInterest ||
    variant === UIVariationState.AvailableOnRequestRegisterInterest ||
    variant === UIVariationState.AvailableOnRequestOnRequest ||
    variant === UIVariationState.AvailableDistributor ||
    variant === UIVariationState.AvailableOnRequestSourcingRequest
  ) {
    return ''
  }
  // AC Default warehouse cutoff time
  // Given a user with default warehouses
  // When viewing a product variant page that shows stock amounts
  // Then display the cut off time for the first default warehouse
  let targetWarehouse = warehouse
  if (
    variant === UIVariationState.AvailableAccurateStockLevel &&
    defaultWarehouses &&
    defaultWarehouses.length > 0
  ) {
    targetWarehouse = defaultWarehouses[0]
  }
  // If no warehouse supplied return error message
  if (!targetWarehouse) {
    return i18nRef.transFn('portfolio_journey:no_target_warehouse')
  }

  const uniqueWarehousesCodes = _.uniq([
    ...(defaultWarehouses || []),
    targetWarehouse
  ])

  const warehouseCodes: (string | undefined)[] | undefined =
    uniqueWarehousesCodes.map((warehouse) => warehouse?.warehouseCode)

  const isOutOfStock: boolean =
    stockLevels.reduce((acc: number, stock: StockDto) => {
      if (
        warehouseCodes &&
        warehouseCodes.filter((code) => code && code === stock.warehouse)
          .length > 0
      ) {
        acc += stock.reservableStock
      }
      return acc
    }, 0) <= 0

  if (isOutOfStock) {
    // If it's a restricted item and there is no stock show no dispatch text
    if (isRestricted) {
      return ''
    }
    if (variant === UIVariationState.AvailableAccurateStockLevel) {
      return i18nRef.transFn('portfolio_journey:new_stock_imminent')
    }
    return i18nRef.transFn('portfolio_journey:ship_when_it_becomes_available')
  }

  return getCutoffTimeForWarehouse(targetWarehouse).isBeforeCutoffTime
    ? ''
    : i18nRef.transFn('portfolio_journey:next_day_dispatch')
}

interface CutOffTime {
  isBeforeCutoffTime: boolean
  timeUntilCutoffMS: number
  timeUntilMessage: string | null
}
/**
 * Get dynamic cutoff time message and work out if cutoff time is reached
 * eg T01 (Yusen - Belgium UTC+1 = CET Europe/Brussels) or C02 (Byfleet -United Kingdom UTC +0 = GMT Europe/London)
 * Yusen cutoff time is 13:00 ByFleet cutoff time is 16:00
 * @param warehouse
 * @return CutOffTime
 */
export const getCutoffTimeForWarehouse = (
  warehouse: WarehouseDto
): CutOffTime => {
  if (!warehouse.orderCutoffTimeLocal) {
    throw new Error(
      `- no 'orderCutoffTimeLocal' supplied for warehouse ${warehouse.warehouseCode}`
    )
  }
  const localCutoffTimeDate = DateTime.fromObject(
    { hour: parseInt(warehouse.orderCutoffTimeLocal) },
    { zone: warehouse.warehouseTimeZone }
  )
  const timeUntilCutoffMS =
    localCutoffTimeDate.diffNow('milliseconds').milliseconds
  const timeUntilMessage = localCutoffTimeDate.toRelative({
    base: DateTime.local()
  })
  return {
    isBeforeCutoffTime: timeUntilCutoffMS > 0,
    timeUntilCutoffMS,
    timeUntilMessage
  }
}

/**
 * Is the a GA drug?
 * @param catalogueItem
 */
export const isGADrug = (
  catalogueItem: CatalogItemDto | IAugmentedCatalogItemDto
): boolean =>
  $enum(DrugDivisionType).asValueOrDefault(
    catalogueItem.item.division,
    DrugDivisionType.UNKNOWN
  ) === DrugDivisionType.GA

/**
 * Is the a CM drug?
 * @param catalogueItem
 */
export const isCMDrug = (
  catalogueItem: CatalogItemDto | IAugmentedCatalogItemDto
): boolean => {
  const drugDivision = $enum(DrugDivisionType).asValueOrDefault(
    catalogueItem.item.division,
    DrugDivisionType.UNKNOWN
  )
  return (
    drugDivision === DrugDivisionType.CM || drugDivision === DrugDivisionType.SP
  )
}

/**
 * Determines whether a distributor exists for that country
 * @param cmPortfolio
 * @param countryCode of the shipping address
 */
export const hasCMDistributorForCountryCode = (
  cmPortfolio: CmPortfolioDto[],
  countryCode: string
): [boolean, string | null] => {
  // Does country exist in table?
  // Is there a distributor?
  const country = cmPortfolio.find((cm) => cm.country === countryCode)
  const distributor = country?.distributor
  return [country !== undefined, distributor === undefined ? null : distributor]
}

/**
 * Determines if a catalogue item is TSE Expired or Restricted status or Supply Problem
 * @param catalogItem
 */
export const getRestrictedProduct = (catalogItem?: CatalogItemDto): boolean => {
  if (!catalogItem) return false
  // Is it a ‘TSE Expired’ or ‘Restricted’ or ‘Active’ items with ‘Supply Problem’ = ‘Yes’
  return (
    catalogItem.item.itemStatus?.toUpperCase() === 'TSE EXPIRE' ||
    catalogItem.item.itemStatus?.toUpperCase() === 'RESTRICTED' ||
    catalogItem.item.supplyProblem?.toUpperCase() === 'Y'
  )
}

/**
 * Determines if a catalogue item is TSE Expired or Restricted status or Supply Problem
 * @param catalogItem
 */
export const getRestrictedAusProduct = (
  catalogItem?: CatalogItemDto
): boolean => {
  if (!catalogItem) return false
  // Is it a ‘TSE Expired’ or ‘Restricted’ or ‘Active’ items with ‘Supply Problem’ = ‘Yes’
  return (
    catalogItem.item.itemStatus?.toUpperCase() === 'TSE EXPIRE' ||
    catalogItem.item.itemStatus?.toUpperCase() === 'RESTRICTED'
  )
}

/**
 * Get the amount of reservable stock from the default warehouse
 * @param catalogItem
 * @param defaultWarehouseCode
 */
export const getAvailableStockForTSEItem = (
  catalogItem: CatalogItemDto,
  defaultWarehouseCode?: string
): number => {
  if (!defaultWarehouseCode) return 0
  const shipFromWarehouse = catalogItem.stockLevels.find(
    (w) => w.warehouse === defaultWarehouseCode
  )
  return shipFromWarehouse ? shipFromWarehouse.reservableStock : 0
}

const getAvailableStatus = (
  isAusProductWithSupplyProblemFlag: boolean,
  isRestricted: boolean,
  outOfStock: boolean
): ProductItemAvailabilityStatus => {
  let availableStatus
  if (isAusProductWithSupplyProblemFlag) {
    availableStatus = ProductItemAvailabilityStatus.LIMITED_AVAILABLE
  } else if (isRestricted && outOfStock) {
    availableStatus = ProductItemAvailabilityStatus.UNAVAILABLE
  } else {
    availableStatus = ProductItemAvailabilityStatus.AVAILABLE
  }
  return availableStatus
}

const getDisplayText = (
  isAusProductWithSupplyProblemFlag: boolean,
  isRestricted: boolean,
  outOfStock: boolean,
  stockLevel: number
): string => {
  let displayText: string
  if (isAusProductWithSupplyProblemFlag) {
    displayText = i18nRef.transFn(
      ProductItemAvailabilityStatusKey.LIMITED_AVAILABLE
    )
  } else if (isRestricted && outOfStock) {
    displayText = i18nRef.transFn(ProductItemAvailabilityStatusKey.UNAVAILABLE)
  } else if (outOfStock) {
    displayText = i18nRef.transFn(ProductItemAvailabilityStatusKey.AVAILABLE)
  } else {
    displayText = i18nRef.transFn('portfolio_journey:packs_available', {
      count: stockLevel
    })
  }
  return displayText
}
const getVariantColor = (
  isAusProductWithSupplyProblemFlag: boolean,
  isRestricted: boolean,
  outOfStock: boolean
): string => {
  let color: string
  if (isAusProductWithSupplyProblemFlag) {
    color = ClinTheme.colors.orangeWarn
  } else if (isRestricted || outOfStock) {
    color = ClinTheme.colors.orangeWarn
  } else {
    color = ClinTheme.colors.greenValid
  }
  return color
}
